import { ApiBase } from "@/api/api-base";
import { FindingCount } from "$/ui-models/dashboard/finding-count";
import { BusinessImportanceLabel } from "$/business-importance";

class DashboardApi extends ApiBase {
  public constructor() {
    super({ pathPrefix: "dashboard", name: "DashboardApi" });
  }

  public async getFindingCounts(minImportance: BusinessImportanceLabel): Promise<FindingCount[]> {
    const url = await this.getUrl("counts");
    url.searchParams.set("minImportance", minImportance);
    const result = await this.client.get<FindingCount[]>(url);
    return result.data;
  }
}

export const Dashboard = new DashboardApi();
