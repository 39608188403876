import { ScmIntegrationType } from "../../../../dynamo";
import { PolicyItemType } from "../../policy-item-base";
import type { MessageModifier } from "../message-modifier";
import { PolicyAction, PolicyActionSubType } from "../policy-action";

declare module "../policy-action" {
  enum PolicyActionSubType {
    pr_comment = "pr_comment",
    pr_comment_recognition = "pr_comment_recognition",
    pr_comment_recognition_aggregated = "pr_comment_recognition_aggregated",
    pr_comment_mention_lookup = "pr_comment_mention_lookup",
    pr_comment_mention_static = "pr_comment_mention_static"
  }
}

(PolicyActionSubType as { pr_comment: "pr_comment" }).pr_comment = "pr_comment";
(PolicyActionSubType as { pr_comment_recognition: "pr_comment_recognition" }).pr_comment_recognition = "pr_comment_recognition";
(PolicyActionSubType as { pr_comment_recognition_aggregated: "pr_comment_recognition_aggregated" }).pr_comment_recognition_aggregated = "pr_comment_recognition_aggregated";
(PolicyActionSubType as { pr_comment_mention_lookup: "pr_comment_mention_lookup" }).pr_comment_mention_lookup = "pr_comment_mention_lookup";
(PolicyActionSubType as { pr_comment_mention_static: "pr_comment_mention_static" }).pr_comment_mention_static = "pr_comment_mention_static";

export const PolicyActionPullRequest = {
  component: {
    type: PolicyItemType.action,
    sub: PolicyActionSubType.pr_comment
  } as const
} as const;

export const PolicyActionPRCommentRecognition = {
  component: {
    type: PolicyItemType.action,
    sub: PolicyActionSubType.pr_comment_recognition
  } as const,
  generateDefault(): PolicyActionPRCommentRecognition {
    return {
      sub: PolicyActionSubType.pr_comment_recognition,
      id: `${PolicyItemType.action}-${Math.floor(Date.now() * Math.random())}`,
      type: PolicyItemType.action,
      name: "",
      includeGif: true,
      mention: false,
      children: []
    };
  }
} as const;

interface IntegrationSpecificMention {
  /**
   * The integration info if applicable
   * @note Do not specify to perform a full lookup
   */
  integration?: ScmIntegrationType;
}

interface IdentitySpecificMention {
  id: string;
  name?: string;
  tag?: string;
}

interface LookupMention<T extends string> {
  lookup: T;
}

interface MentionBase<T extends string> {
  type: T;
}

interface GroupMention extends MentionBase<"group">, IdentitySpecificMention, IntegrationSpecificMention {}
interface UserMention extends MentionBase<"user">, IdentitySpecificMention, IntegrationSpecificMention {}
interface PusherMention extends MentionBase<"user">, LookupMention<"pusher"> {}
interface AuthorMention extends MentionBase<"user">, LookupMention<"author"> {}
interface CommitterMention extends MentionBase<"user">, LookupMention<"committer"> {}
interface ProductMention extends MentionBase<"user">, LookupMention<"product"> {}

export interface PolicyActionPRCommentBase<
  C extends PolicyActionPRCommentMentionLookup | PolicyActionPRCommentMentionStatic = PolicyActionPRCommentMentionLookup | PolicyActionPRCommentMentionStatic
> extends PolicyAction {
  mention: boolean;
  children: C[];
}

export interface PolicyActionPRComment extends PolicyActionPRCommentBase {
  sub: PolicyActionSubType.pr_comment;
  modifiers?: MessageModifier[];
}

export interface PolicyActionPRCommentRecognition extends PolicyActionPRCommentBase<PolicyActionPRCommentMentionStatic> {
  sub: PolicyActionSubType.pr_comment_recognition;
  includeGif: boolean;
}

export interface PolicyActionPRCommentRecognitionAggregated extends PolicyActionPRCommentBase<PolicyActionPRCommentMentionStatic> {
  sub: PolicyActionSubType.pr_comment_recognition_aggregated;
  includeGif: boolean;
}

export interface PolicyActionPRCommentMentionLookup extends PolicyAction {
  sub: PolicyActionSubType.pr_comment_mention_lookup;

  /**
   * The mention configuration
   */
  to: PusherMention | AuthorMention | CommitterMention | ProductMention | null;
}

export interface PolicyActionPRCommentMentionStatic extends PolicyAction {
  sub: PolicyActionSubType.pr_comment_mention_static;

  /**
   * The mention configuration
   */
  to: GroupMention | UserMention | null;
}

export type PolicyActionPRCommentMention = PolicyActionPRCommentMentionLookup | PolicyActionPRCommentMentionStatic;
export type PolicyActionPRCommentType = PolicyActionPRComment | PolicyActionPRCommentRecognition | PolicyActionPRCommentRecognitionAggregated;

const PolicyActionPRCommentMentionLookup = {
  generateDefault(): PolicyActionPRCommentMentionLookup {
    return {
      sub: PolicyActionSubType.pr_comment_mention_lookup,
      id: `${PolicyItemType.action}-${Math.floor(Date.now() * Math.random())}`,
      type: PolicyItemType.action,
      name: "",
      to: {
        lookup: "author",
        type: "user"
      }
    };
  }
} as const;

export const PolicyActionPRCommentMention = {
  is: (action: PolicyAction): action is PolicyActionPRCommentMention => {
    return PolicyAction.isOrParentOf(action, PolicyActionSubType.pr_comment_mention_lookup) || PolicyAction.isOrParentOf(action, PolicyActionSubType.pr_comment_mention_static);
  }
};

export const PolicyActionPRCommentMentionStatic = {
  is: (action: PolicyAction): action is PolicyActionPRCommentMentionStatic => {
    return PolicyAction.isOrParentOf(action, PolicyActionSubType.pr_comment_mention_static);
  }
};

export const PolicyActionPRComment = {
  generateDefault(): PolicyActionPRComment {
    return {
      sub: PolicyActionSubType.pr_comment,
      id: `${PolicyItemType.action}-${Math.floor(Date.now() * Math.random())}`,
      type: PolicyItemType.action,
      name: "",
      mention: true,
      children: [PolicyActionPRCommentMentionLookup.generateDefault()]
    };
  },
  is: (action: PolicyAction): action is PolicyActionPRComment => {
    return PolicyAction.isOrParentOf(action, PolicyActionSubType.pr_comment);
  }
} as const;
