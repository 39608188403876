<template>
  <v-row class="component loading-centered" align="center" justify="center">
    <v-col align="center">
      <div class="logo-container">
        <svg class="a" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="400" height="400" viewBox="0, 0, 400,400">
          <g id="svgg">
            <path
              id="path0"
              d="M184.972 135.976 C 133.061 142.676,111.858 212.468,150.000 251.096 C 170.750 272.111,210.004 275.127,226.411 256.967 L 230.000 252.994 230.000 258.997 L 230.000 265.000 249.000 265.000 L 268.000 265.000 268.000 202.500 L 268.000 140.000 249.000 140.000 L 230.000 140.000 230.000 145.500 L 230.000 151.000 225.901 146.901 C 216.886 137.886,201.661 133.822,184.972 135.976 M213.393 174.404 C 237.109 186.007,234.490 223.596,209.416 231.483 C 180.406 240.607,158.004 208.351,175.627 182.830 C 183.321 171.687,200.161 167.930,213.393 174.404 "
              stroke="none"
              fill="#152428"
              fill-rule="evenodd"></path>
          </g>
        </svg>
        <svg class="brackets left" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="400" height="400" viewBox="0, 0, 400,400">
          <g id="svgg">
            <path
              id="path0"
              d="M34.959 38.867 C 34.711 39.268,34.778 358.476,35.026 359.400 C 35.240 360.196,35.579 360.200,99.982 360.200 C 135.590 360.200,164.516 360.068,164.262 359.906 C 164.008 359.744,163.957 359.609,164.149 359.606 C 164.341 359.603,164.265 359.320,163.981 358.977 C 163.554 358.463,163.563 358.376,164.032 358.477 C 164.345 358.545,164.690 358.718,164.800 358.863 C 164.910 359.007,164.948 352.542,164.886 344.495 C 164.795 332.925,164.879 329.788,165.286 329.498 C 165.639 329.246,165.518 329.193,164.899 329.328 C 164.404 329.437,164.087 329.383,164.195 329.208 C 164.901 328.065,162.486 328.001,114.761 327.902 L 66.200 327.800 66.099 199.100 L 65.999 70.400 114.999 70.400 C 153.930 70.400,164.001 70.297,164.006 69.900 C 164.011 69.515,164.088 69.519,164.341 69.919 C 164.607 70.337,164.765 70.324,165.155 69.855 C 165.484 69.458,165.505 69.188,165.219 69.012 C 164.940 68.839,164.800 64.034,164.800 54.576 C 164.800 45.392,164.659 40.400,164.400 40.400"
              fill-rule="evenodd"></path>
          </g>
        </svg>
        <svg class="brackets right" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="400" height="400" viewBox="0, 0, 400,400">
          <g id="svgg">
            <path
              id="path0"
              d="M34.959 38.867 C 34.711 39.268,34.778 358.476,35.026 359.400 C 35.240 360.196,35.579 360.200,99.982 360.200 C 135.590 360.200,164.516 360.068,164.262 359.906 C 164.008 359.744,163.957 359.609,164.149 359.606 C 164.341 359.603,164.265 359.320,163.981 358.977 C 163.554 358.463,163.563 358.376,164.032 358.477 C 164.345 358.545,164.690 358.718,164.800 358.863 C 164.910 359.007,164.948 352.542,164.886 344.495 C 164.795 332.925,164.879 329.788,165.286 329.498 C 165.639 329.246,165.518 329.193,164.899 329.328 C 164.404 329.437,164.087 329.383,164.195 329.208 C 164.901 328.065,162.486 328.001,114.761 327.902 L 66.200 327.800 66.099 199.100 L 65.999 70.400 114.999 70.400 C 153.930 70.400,164.001 70.297,164.006 69.900 C 164.011 69.515,164.088 69.519,164.341 69.919 C 164.607 70.337,164.765 70.324,165.155 69.855 C 165.484 69.458,165.505 69.188,165.219 69.012 C 164.940 68.839,164.800 64.034,164.800 54.576 C 164.800 45.392,164.659 40.400,164.400 40.400"
              fill-rule="evenodd"></path>
          </g>
        </svg>
      </div>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

@Component
export class LoadingCentered extends Vue {}

export default LoadingCentered;
</script>
<style lang="scss">
.theme--dark {
  .a #path0 {
    fill: #fff;
  }
}
.logo-container {
  position: relative;
  width: 150px;
  height: 150px;
  perspective: 1000px;
  display: flex;
  .a {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
  .brackets {
    position: absolute;
    width: 100%;
    height: 100%;
    stroke: none;
    fill: $primary;
    animation-delay: 1s;
    transform-origin: center;
    &.left {
      animation: rotation-left 6s linear infinite;
      z-index: 2;
    }
    &.right {
      animation: rotation-right 6s linear infinite;
      rotate: y 180deg;
    }
  }
  @keyframes rotation-left {
    0% {
      transform: rotateY(0deg); /* Start with Y-axis rotation */
    }
    10% {
      z-index: 0;
    }
    20% {
      transform: rotateY(360deg); /* Complete Y-axis rotation */
    }
    50% {
      transform: rotateY(360deg); /* Pause after Y-axis rotation */
    }
    70% {
      transform: rotateY(360deg) rotateZ(360deg); /* Complete Z-axis rotation */
    }
    100% {
      transform: rotateY(360deg) rotateZ(360deg); /* Pause after Z-axis rotation */
    }
  }
  @keyframes rotation-right {
    0% {
      transform: rotateY(0deg); /* Start with Y-axis rotation */
    }
    20% {
      transform: rotateY(360deg); /* Complete Y-axis rotation */
    }
    25% {
      z-index: 2;
    }
    50% {
      transform: rotateY(360deg); /* Pause after Y-axis rotation */
    }
    70% {
      transform: rotateY(360deg) rotateZ(-360deg); /* Complete Z-axis rotation */
    }
    100% {
      transform: rotateY(360deg) rotateZ(-360deg); /* Pause after Z-axis rotation */
    }
  }
}
</style>
