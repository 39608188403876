import Vue from "vue";
import { Products as Api } from "@/api";
import { UISimpleProduct } from "$/ui-models/products-v2/ui-simple-product";

class ProductsState {
  private products: Promise<UISimpleProduct[]> | null = null;

  public constructor() {
    Vue.observable(this);
  }

  public list(refresh = false): Promise<UISimpleProduct[]> {
    if (refresh || !this.products) {
      this.products = Api.listSimple();
    }
    return this.products;
  }

}

export const Products = new ProductsState();
