import { PolicyItemType } from "../../policy-item-base";
import { PolicyCondition, PolicyConditionSubType } from "../policy-condition";

declare module "../policy-condition" {
  enum PolicyConditionSubType {
    has_product_matching_labels = "has_product_matching_labels",
    product_labels = "product_labels",
    product_or = "product_or",
    product_and = "product_and",
    product_not = "product_not"
  }
}

(PolicyConditionSubType as { has_product_matching_labels: "has_product_matching_labels" }).has_product_matching_labels = "has_product_matching_labels";
(PolicyConditionSubType as { product_labels: "product_labels" }).product_labels = "product_labels";
(PolicyConditionSubType as { product_or: "product_or" }).product_or = "product_or";
(PolicyConditionSubType as { product_and: "product_and" }).product_and = "product_and";
(PolicyConditionSubType as { product_not: "product_not" }).product_not = "product_not";

export type PolicyConditionProductSubType = PolicyConditionSubType.has_product_matching_labels | PolicyConditionSubType.product_labels | PolicyConditionSubType.product_or | PolicyConditionSubType.product_and | PolicyConditionSubType.product_not;
export const PolicyConditionProductSubType = {
  list(): PolicyConditionProductSubType[] {
    return [
      PolicyConditionSubType.has_product_matching_labels,
      PolicyConditionSubType.product_labels,
      PolicyConditionSubType.product_or,
      PolicyConditionSubType.product_and,
      PolicyConditionSubType.product_not
    ];
  }
}

export interface PolicyConditionProduct extends PolicyCondition {
  sub: PolicyConditionProductSubType;
}

export interface PolicyConditionProductLabels extends PolicyConditionProduct {
  sub: PolicyConditionSubType.product_labels;
  labels?: string[];
}

export interface PolicyConditionProductHasProductMatchingLabels extends PolicyConditionProduct {
  sub: PolicyConditionSubType.product_or | PolicyConditionSubType.product_and | PolicyConditionSubType.product_not;
  child: PolicyConditionProduct;
}

export interface PolicyConditionProductBoolean extends PolicyConditionProduct {
  sub: PolicyConditionSubType.product_or | PolicyConditionSubType.product_and | PolicyConditionSubType.product_not;
  children: PolicyConditionProduct[];
}

export const PolicyConditionProduct = {
  rootComponent: {
    type: PolicyItemType.condition,
    sub: PolicyConditionSubType.has_product_matching_labels
  },
  components: [
    {
      type: PolicyItemType.condition,
      sub: PolicyConditionSubType.product_labels
    },
    {
      type: PolicyItemType.condition,
      sub: PolicyConditionSubType.product_or
    },
    {
      type: PolicyItemType.condition,
      sub: PolicyConditionSubType.product_and
    },
    {
      type: PolicyItemType.condition,
      sub: PolicyConditionSubType.product_not
    }
  ] as const
} as const;
