import { PolicyItemBase, PolicyItemType } from "../policy-item-base";
import type { PolicyRule } from "../policy-rule";

declare module "../policy-item-base" {
  interface PolicyComponents {
    [PolicyItemType.condition]: PolicyCondition;
  }

  enum PolicyItemType {
    condition = "condition"
  }
}

(PolicyItemType as { condition: "condition" }).condition = "condition";

/**
 * A list of all condition sub types.
 * @note This enum should be augmented in modules to add new types of policy components.
 */
export enum PolicyConditionSubType {}
(PolicyConditionSubType as unknown) = {
  /** es-build minification workaround */
};

export interface PolicyCondition extends PolicyItemBase<PolicyItemType.condition> {
  sub: PolicyConditionSubType;
  name?: string;
  children?: PolicyCondition[];
}

export type PolicyConditionCategory = "Logical" | "Asset" | "Product" | "Finding" | "Package" | "License" | "Software Composition Analysis (SCA)" | "Reputation" | "Static Application Security Testing (SAST)";

export const PolicyCondition = {
  isOrParentOf(condition: PolicyCondition, sub: PolicyConditionSubType): boolean {
    return condition.sub === sub || !!condition.children?.some((c) => PolicyCondition.isOrParentOf(c, sub));
  },
  inRule(rule: PolicyRule, sub: PolicyConditionSubType): boolean {
    return !!rule.condition && (rule.condition.sub === sub || !!rule.condition.children?.some((c) => PolicyCondition.isOrParentOf(c, sub)));
  },
  sortProps(condition: PolicyCondition): PolicyCondition {
    const { children, id, name, sub, type, ...rest} = condition;
    return {
      type,
      sub,
      name,
      id,
      ...rest,
      children: children?.nonNullable().map((c) => PolicyCondition.sortProps(c))
    };
  }
} as const;
